<template>
  <section
    style="padding-left: 25px;
  margin-left: 20px; padding-right: 10px;
  margin-right: 10px;"
  >
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-row style="margin:15px">
        <v-col
          cols="12"
          sm="12"
          md="4"
          v-for="(Actitem, index) in ActualityCategoryList"
          v-bind:key="index"
        >
          <v-card
            style="padding:15px ;  background: linear-gradient(to right, rgb(231, 217, 186), rgb(232, 218, 185))"
          >
            <v-card-title class="text-h5">
              <span style="color: #000000;font-weight: bold;">{{
                Actitem.champName
              }}</span>
            </v-card-title>

            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>

              <v-btn
                @click="redirectToActualityCategorie(Actitem)"
                color="#00000"
                text
              >
                Voire Plus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title>Liste des Actualités</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :search="search"
        :loading="isLoadingTables"
        loading-text="Loading..."
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        class="elevation-1"
        item-key="id"
        :headers="headers"
        :items="listActualityList"
      >
        <template v-slot:item.imgUrl="{ item }">
          <v-img
            style="margin:10px"
            :src="item.imgUrl"
            max-width="100"
            max-height="100"
          />
          <v-divider></v-divider>
        </template>
        <template v-slot:item.actualityCategory="{ item }">
          {{ formatCategory(item.actualityCategory) }}
        </template>
        <template v-slot:item.isLocked="{ item }">
          <v-icon color="warning" v-if="item.isLocked">
            {{ icons.mdiLock }}
          </v-icon>
          <v-icon color="#00a325" v-if="!item.isLocked">
            {{ icons.mdiLockOpen }}
          </v-icon>
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="redirectToEditActuality(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>
        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="suppressionAction(item.id)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialogAddActuality" max-width="530">
        <v-card class="pa-6 pa-sm-6" max-width="600px">
          <v-card-title style="text-align: center;" class="justify-center mb-2"
            >Êtes-vous sûr de vouloir ajouter l'actualité
          </v-card-title>
          <v-card-subtitle
            class="text-h6 mb-3"
            style="text-align: center; color: #24aeb1;"
          >
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="ajoutactuality()" color="info darken-1 " text>
              Confirmer
            </v-btn>
            <v-btn
              @click="dialogAddActuality = false"
              color="error darken-1 error"
              text
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog max-width="530" v-model="dialogRemoveActuality">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer l'actualité ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deleteActuality()" color="info darken-1 " text>
              Confirmer
            </v-btn>
            <v-btn
              @click="dialogRemoveActuality = false"
              color="error darken-1 error"
              text
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiLockOpen,
  mdiLock,
} from "@mdi/js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
import Constant from "@/utils/constants";
import {
  getAllActualityCategory,
  getAllActuality,
} from "@/views/qorani/services/services";
export default {
  components: { VueUploadMultipleImage },
  data() {
    return {
      search: "",
      dialogRemoveActuality: false,
      headers: [
        { text: "Image", value: "imgUrl" },
        { text: "Titre", value: "title" },
        { text: "Catégorie", value: "actualityCategory" },
        { text: "Premium", value: "isLocked" },
        { text: "Modifier", value: "modifier", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
      listActualityList: [],
      isLoadingTables: false,
      actualityInfo: {
        title: "",
        subtitle: "",
        description: "",
        isLocked: false,
        actualityCategoryId: "",
        imgUrlId: "",
      },
      dialogAddActuality: false,
      title: "",
      subtitle: "",
      description: "",
      actualityCategoryId: "",
      ActualityCategoryList: [],
      Premium: false,
      addCard: false,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiLockOpen,
        mdiLock,
      },
      images: [],
      imagesUpload: [],
      imageUrl: "",
      imageId: "",
      actualityIdSupprimmer: "",
    };
  },
  methods: {
    redirectToActualityCategorie(item) {
      this.$router.push({
        name: "ActualityCategorie",
        params: { id: item.id, category: item.category },
      });
    },
    redirectToEditActuality(item) {
      this.$router.push({
        name: "EditActuality",
        params: { id: item.id, item: item },
      });
    },
    async deleteActuality() {
      const response = await fetch(
        Constant.QORANI + `actuality/delete/${this.actualityIdSupprimmer}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogRemoveActuality = false;
      }
    },
    suppressionAction(item) {
      this.actualityIdSupprimmer = item;
      this.dialogRemoveActuality = true;
    },

    formatCategory(inputString) {
      const words = inputString.toLowerCase().split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    },
  },
  async created() {
    this.isLoadingTables = true;
    this.listActualityList = await getAllActuality();
    this.ActualityCategoryList = await getAllActualityCategory();
    this.ActualityCategoryList.forEach((item) => {
      const words = item.category.toLowerCase().split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      item.champName = capitalizedWords.join(" ");
    });
    this.isLoadingTables = false;
  },
};
</script>

<style>
.transparent-card {
  padding-right: 15px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: transparent !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding-left":"25px","margin-left":"20px","padding-right":"10px","margin-right":"10px"}},[_c('v-card',{staticClass:"mb-6 ml-6 mt-6 mr-2"},[_c('v-row',{staticStyle:{"margin":"15px"}},_vm._l((_vm.ActualityCategoryList),function(Actitem,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{staticStyle:{"padding":"15px","background":"linear-gradient(to right, rgb(231, 217, 186), rgb(232, 218, 185))"}},[_c('v-card-title',{staticClass:"text-h5"},[_c('span',{staticStyle:{"color":"#000000","font-weight":"bold"}},[_vm._v(_vm._s(Actitem.champName))])]),_c('v-card-actions',{staticClass:"mt-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#00000","text":""},on:{"click":function($event){return _vm.redirectToActualityCategorie(Actitem)}}},[_vm._v(" Voire Plus ")])],1)],1)],1)}),1)],1),_c('v-card',{staticClass:"mb-6 ml-6 mt-6 mr-2"},[_c('v-card-title',[_vm._v("Liste des Actualités")]),_c('v-text-field',{staticStyle:{"padding":"15px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","search":_vm.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"loading":_vm.isLoadingTables,"loading-text":"Loading...","footer-props":{
        'items-per-page-options': [5, 10, 15, 30],
      },"item-key":"id","headers":_vm.headers,"items":_vm.listActualityList},scopedSlots:_vm._u([{key:"item.imgUrl",fn:function(ref){
      var item = ref.item;
return [_c('v-img',{staticStyle:{"margin":"10px"},attrs:{"src":item.imgUrl,"max-width":"100","max-height":"100"}}),_c('v-divider')]}},{key:"item.actualityCategory",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCategory(item.actualityCategory))+" ")]}},{key:"item.isLocked",fn:function(ref){
      var item = ref.item;
return [(item.isLocked)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLock)+" ")]):_vm._e(),(!item.isLocked)?_c('v-icon',{attrs:{"color":"#00a325"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLockOpen)+" ")]):_vm._e()]}},{key:"item.modifier",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.redirectToEditActuality(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.supprimer",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.suppressionAction(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"530"},model:{value:(_vm.dialogAddActuality),callback:function ($$v) {_vm.dialogAddActuality=$$v},expression:"dialogAddActuality"}},[_c('v-card',{staticClass:"pa-6 pa-sm-6",attrs:{"max-width":"600px"}},[_c('v-card-title',{staticClass:"justify-center mb-2",staticStyle:{"text-align":"center"}},[_vm._v("Êtes-vous sûr de vouloir ajouter l'actualité ")]),_c('v-card-subtitle',{staticClass:"text-h6 mb-3",staticStyle:{"text-align":"center","color":"#24aeb1"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1 ","text":""},on:{"click":function($event){return _vm.ajoutactuality()}}},[_vm._v(" Confirmer ")]),_c('v-btn',{attrs:{"color":"error darken-1 error","text":""},on:{"click":function($event){_vm.dialogAddActuality = false}}},[_vm._v(" Annuler ")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"530"},model:{value:(_vm.dialogRemoveActuality),callback:function ($$v) {_vm.dialogRemoveActuality=$$v},expression:"dialogRemoveActuality"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Etes vous sur de vouloir supprimer l'actualité ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1 ","text":""},on:{"click":function($event){return _vm.deleteActuality()}}},[_vm._v(" Confirmer ")]),_c('v-btn',{attrs:{"color":"error darken-1 error","text":""},on:{"click":function($event){_vm.dialogRemoveActuality = false}}},[_vm._v(" Annuler ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }